/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState} from "react";
import { CssCases } from "./css";

import Suvinil from "@/assets/imagens/caseSuvinil.png"
import Ecom from "@/assets/imagens/caseEcom.png"
import SuperBonder from "@/assets/imagens/caseSuperBond.png"

import { Dialog } from 'primereact/dialog';

const Cases = () => {
	let larguraTela = window.innerWidth;
  //alert(larguraTela)
//Modal
	const [modalVideo, setModalVideo] = useState(false); 
	const [link, setLink] = useState(null); 
	const [descricao, setDescricao] = useState(null); 
	const [alturaVideo, setAlturaVideo] = useState("460px"); 

	useEffect(() => {
		if(larguraTela < 850){
			setAlturaVideo("230px")
		}  
	}, [larguraTela]);

	const toggleVideo = async (url, titulo) =>{
		setModalVideo(!modalVideo)
		if(url !== undefined){
			setLink(url)
			setDescricao(titulo)	
		}else{
			setLink(null)
			setDescricao(null)
		}
	};

	useEffect(() => {
		
	}, []);

  return (
 	<CssCases>	
		<div id="background">
			<div className="container">
				<div id="cases">
					<div id="borrao">
						<h5>Cases</h5>
						<h6>Nossos cases são mais do que resultados, são histórias de transformação.</h6>
						<p>
							Cada projeto é uma jornada <span>única</span>, onde conectamos criatividade, estratégia e inovação para gerar impacto real.
						</p>
						<p>
							Conheça algumas das experiências que construímos ao longo dos anos e veja como elevamos marcas a novos patamares.
						</p>
						<a href="#faleConosco"> fale conosco</a>
					</div>

					<div id="portifolio">
						<section>
							<img src={Suvinil} alt="Case Suvinil" title="Case Suvinil" onClick={() => toggleVideo("https://player.vimeo.com/video/1002934066", "Suvinil")} />
						</section>
						<section>
							<img src={Ecom} alt="Case Ecom" title="Case Ecom"  onClick={() => toggleVideo("https://player.vimeo.com/video/1059824893", "Ecom")} />
						</section>
						<section>
							<img src={SuperBonder} alt="Case SuperBonder" title="Case SuperBonder" onClick={() => toggleVideo("https://player.vimeo.com/video/1059827059", "SuperBond")} />
						</section>
						
							<p><a href="https://vimeo.com/user222353442" target="_blank">veja mais</a></p>
					
					</div> 
						
				</div>
			</div>
		</div>	

		<Dialog 
			header={descricao} 
			breakpoints={{'960px': '75vw', '640px': '100vw'}} 
			visible={modalVideo} 
			onHide={() => toggleVideo()} 
			id="lgCases"
		>
			<iframe src={link} width="100%" height={alturaVideo} allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
		</Dialog> 	

	</CssCases> 
  );  
}

export default Cases;