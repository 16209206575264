import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box
    }

    a{
        text-decoration: none
    }

    *:focus {
        outline: none;
    }

    html{
        scroll-behavior: smooth;
        margin: 0;
        padding: 0;
		background-color: #000; 
    }	


    body{
        font-family: "Lato", serif;
		-webkit-font-smoothing: antialiased;
  		-moz-osx-font-smoothing: grayscale;
		  text-rendering: optimizeLegibility;
        font-optical-sizing: auto;
        font-weight: 400;
        font-style: normal;
		
    }

    h1, h2, h3, h4, h5, h6, strong{
        font-weight: 600;
    }
 
    .mb{
        display: none
    }

    .pc{
        display: inline-block
    }

    .fullContent{
        max-width: 1920px;
        width: 100%; 
        margin: 0 auto;
        position: relative;
        padding: 0 1%;
    }

    .img{
        width: 100%;
        height: auto
    }

	#lgVideo{
		width: 100%;
		max-width: 810px;
    	margin: 1%;
		padding: 10px;
	}

	#lgCases{
		width: 100%;
		max-width: 810px;
    	margin: 1%;
		padding: 10px;
	}

	.p-dialog .p-dialog-header{
		padding: 10px !important
	}

	iframe{
		border: none
	}

	.p-component{
		font-family: "Lato", serif;
		font-weight: 300
	}

	.container {
		max-width: 1366px;
		width: 100%;  /* Largura fixa */
		margin: 0 auto;  /* Centraliza horizontalmente */
		padding: 0 20px; /* Adiciona um pequeno espaço nas laterais para evitar que o conteúdo fique colado */
	}


    @media only screen and (max-width: 1024px) {
        .fullContent{        
            padding: 2%;        
        }

    }



    @media only screen and (max-width: 800px) {
     
        .mb{
            display: inline-block
        }

        .pc{
            display: none
        }
        
    }

    @media only screen and (max-width: 480px) {

		.container{
			padding: 0 10px;
		}
       
    }



`