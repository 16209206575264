/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState} from "react";
import { CssQuem } from "./css";
import 'primeicons/primeicons.css';

import linha from "@/assets/imagens/linha.png"
import dedo from "@/assets/imagens/dedo.png"

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Suvinil from "@/assets/logotipos/logo-suvinil.png"
import Ecom from "@/assets/logotipos/logo-ecom.png"
import Edp from "@/assets/logotipos/logo-edp.png"
import PG from "@/assets/logotipos/logo-p&g.png"
import Evino from "@/assets/logotipos/logo-evino.png"
import basf from "@/assets/logotipos/logo-basf.png"
import dupont from "@/assets/logotipos/logo-dupont.png"
import GranCru from "@/assets/logotipos/logo-grand-cru.png"
import Lbca from "@/assets/logotipos/logo-lbca.png"
import Financiamento from "@/assets/logotipos/logon-meu-financiamento-solar.png"
import Oxxo from "@/assets/logotipos/logo-oxxo.png"
import Neo from "@/assets/logotipos/logo-neo.png"
import SaintGoban from "@/assets/logotipos/logo-saint-goban.png"
import TemSaude from "@/assets/logotipos/logo-tem-saude.png"

import Video from "@/assets/imagens/video.svg"

import { Dialog } from 'primereact/dialog';

//logo-zest1-branco
const QuemSomos = () => {
	let larguraTela = window.innerWidth;
	//let alturaTela = window.innerHeight;
	//alert(alturaTela)
	//console.log("🚀 ~ QuemSomos ~ alturaTela:", alturaTela)

	//Modal
	const [modalVideo, setModalVideo] = useState(false); 
	const [link, setLink] = useState(null); 
	const [alturaVideo, setAlturaVideo] = useState("460px"); 

	const settings = {
		dots: false, // Exibe pontos de navegação
		infinite: true, // Ciclo infinito
		speed: 500, // Velocidade da transição
		slidesToShow: 5, // Exibe 5 elementos por vez
		slidesToScroll: 1, // Move 1 elemento por vez
		responsive: [
		  {
			breakpoint: 1024, // Para telas maiores que 1024px
			settings: {
			  slidesToShow: 3, // Mostra 3 itens
			},
		  },
		  {
			breakpoint: 600, // Para telas menores que 600px
			settings: {
			  slidesToShow: 1, // Mostra 1 item
			},
		  },
		],
	  };

	useEffect(() => {
		if(larguraTela < 850){
            setAlturaVideo("230px")
        }  
	}, [larguraTela]);

	const toggleVideo = async (url) =>{
		setModalVideo(!modalVideo)
		if(url !== undefined){
			setLink(url)	
		}else{
			setLink(null)
		}
	};

  return (
 	<CssQuem>
		<div className="container">
			<div id="quemSomos">
				<h1>
					Somos uma agência <span>brasileira</span> que transforma marcas em <span>creators</span>.
				</h1>

				<h2>
				Com quase uma década de experiência, combinamos <strong>estratégia</strong> e <strong>criatividade</strong> para <span/>gerar conexões autênticas e criar impacto real.
				</h2>			
			</div>	

			<div id="linha">
				<img src={linha} alt="linha" />
			</div>	

			<div id="arraste">
				<p>arraste para o lado e descubra</p>
				<h3>Quem já está com a <i>Zest</i></h3>

				<img src={dedo} alt="linha" width={"34px"} height={"38px"} />
			</div>

			<div id="clientes">			
				<Slider {...settings}>
					<div>
						<img src={Suvinil} alt="Suvinil" title="Suvinil" width={'100%'}/>
					</div>
					<div>
						<img src={Ecom} alt="Ecom" title="Ecom" width={'100%'}/>
					</div>
					<div>
						<img src={Edp} alt="Edp" title="Edp" width={'100%'}/>
					</div>
					<div>
						<img src={PG} alt="P&G" title="P&G" width={'100%'}/>
					</div>
					<div>
						<img src={Evino} alt="Evino" title="Evino" width={'100%'}/>
					</div>
					<div>
						<img src={basf} alt="basf" title="basf" width={'100%'} />
					</div>
					<div>
						<img src={dupont} alt="Dupont" title="Dupont" width={'100%'}/>
					</div>
					<div>
						<img src={GranCru} alt="GranCru" title="GranCru" width={'100%'}/>
					</div>
					<div>
						<img src={Lbca} alt="Lbca" title="Lbca" width={'100%'}/>
					</div>
					<div>
						<img src={Financiamento} alt="Financiamento Solar" title="Financiamento Solar" width={'100%'}/>
					</div>
					<div>
						<img src={Oxxo} alt="Oxxo" title="Oxxo" width={'100%'}/>
					</div>
					<div>
						<img src={Neo} alt="Neo" title="Neo" width={'100%'}/>
					</div>
					<div>
						<img src={SaintGoban} alt="SaintGoban" title="SaintGoban" width={'100%'}/>
					</div>
					<div>
						<img src={TemSaude} alt="Tem tudo para cuidar da saúde" title="Tem tudo para cuidar da saúde" width={'100%'}/>
					</div>
				</Slider>
			</div>

			<div id="video">
				<img src={Video} alt="Video" title="Video" width={'100%'} onClick={() => toggleVideo("https://player.vimeo.com/video/1012860389")}/>
			</div>
		</div>		

		<Dialog header={"Vem com a Zest"} breakpoints={{'960px': '75vw', '640px': '100vw'}} visible={modalVideo} onHide={() => toggleVideo()} id="lgVideo">
			<iframe src={link} width="100%" height={alturaVideo} allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
		</Dialog> 	

	</CssQuem> 
  );  
}

export default QuemSomos;