/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { CssHome } from "./css";
import { Header } from "@/components/Header";
import 'primeicons/primeicons.css';

import LogoZest from "@/assets/imagens/logo-zest-branco.svg"
import QuemSomos from "./quemSomos";
import Jobs from "./jobs";
import Cases from "./cases";
import FaleConosco from "./faleConosco";

//let larguraTela = window.innerWidth;   
//let alturaTela = window.innerHeight;	

//logo-zest1-branco
const Index = () => {
  document.title = "Zest One - Agência brasileira que transforam marcas em creators.";

  const palavras = ["creativity", "solutions", "ideas"];
  const [index, setIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false); // Aplica o fade-out e movimento

      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % palavras.length);
        setFade(true); // Aplica o fade-in e movimento inverso
      }, 500); // Tempo do fade-out antes da troca

    }, 3000); // Tempo total para troca de palavra

    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
		<Header />

		<CssHome>
			<div id="container">
				<div id="Logo">
					<img src={LogoZest} alt="Zest One" />
				</div>
				<div id="texto" className={fade ? "move-in" : "move-out"}>
					The house of <strong className={fade ? "fade-in" : "fade-out"}>{palavras[index]}</strong>
				</div>
				<a href="#quemSomos"  id="saibaMais" >
					Saiba Mais <i className="pi pi-chevron-down"></i>
				</a>
			</div>
		</CssHome> 
		
		<QuemSomos /> 		

		<Jobs /> 		

		<Cases /> 

		<FaleConosco />
    </>
   
  );  
}

export default Index;