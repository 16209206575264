/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { CssJobs } from "./css";
import 'primeicons/primeicons.css';

import planejamento from "@/assets/imagens/icon-planejamento.png"
import marketing from "@/assets/imagens/icon-marketing.png"
import conteudo from "@/assets/imagens/icon-conteudo.png"
import eventos from "@/assets/imagens/icon-eventos.png"
import campanhas from "@/assets/imagens/icon-campanhas.png"
import inbound from "@/assets/imagens/icon-inbound.png"


const Jobs = () => {

  return (
 	<CssJobs>
		<div id="trabalhos">
			<div className="container">
				<div id="lateral">
					<h4>Jobs</h4>
					<p>
						Não entregamos <span>projetos</span>, <br/>
						entregamos <span>resultados</span>.
					</p>
					<p>
						<a href="#faleConosco">
							fale conosco
						</a>
					</p>
					<div className="clear"></div>
				</div>

				<div id="jobs">
					<div className="job">
						<img src={planejamento} alt="Planejamento Estratégico" title="Planejamento Estratégico" width="76" height="76"/>
						<p>
							<strong>Planejamento Estratégico</strong>
							<small>Estratégias inteligentes para posicionar marcas com relevância e impacto.</small>
						</p>
					</div>
					<div className="job">
						<img src={marketing} alt="Marketing de Influência" title="Marketing de Influência" width="76" height="76"/>
						<p>
							<strong>Marketing de Influência</strong>
							<small>Estratégia e gestão de parcerias com creators para amplificar narrativas.</small>
						</p>
					</div>
					<div className="job">
						<img src={conteudo} alt="Conteúdo & Social Media" title="Conteúdo & Social Media" width="76" height="76"/>
						<p>
							<strong>Conteúdo & Social Media</strong>
							<small>Produção estratégica e gestão de redes para marcas que querem crescer.</small>
						</p>
					</div>
					<div className="job">
						<img src={eventos} alt="Eventos & Ativações" title="Eventos & Ativações" width="76" height="76"/>
						<p>
							<strong>Eventos & Ativações</strong>
							<small>Criação de experiências imersivas que conectam público e marca.</small>
						</p>
					</div>
					<div className="job">
						<img src={campanhas} alt="Campanhas Publicitárias" title="Campanhas Publicitárias" width="76" height="76"/>
						<p>
							<strong>Campanhas Publicitárias</strong>
							<small>Conceito, criação e execução de campanhas que geram engajamento.</small>
						</p>
					</div>
					<div className="job">
						<img src={inbound} alt="Inbound & Infoproduto" title="Inbound & Infoproduto" width="76" height="76"/>
						<p>
							<strong>Inbound & Infoproduto</strong>
							<small>Jornada digital orientada a conversão e crescimento sustentável.</small>
						</p>
					</div>
				</div>
			</div>
		</div>		
	</CssJobs> 
  );  
}

export default Jobs;