import styled from 'styled-components';

export const HeaderCss = styled.header`

   #header{
        width: 100%; 
        padding: 5px 0;
        height: 90px;
        top: 0;
        left: 0;
        z-index: 50; 
        float: left;
        transition: 0.5s;
        position: absolute;
        top: 0;
        left:0;

        .whats{
            width: 24px;
            position: absolute;
            top: -65px;
            right:75px;
			opacity: 0.6;
			transition: 0.2s;
			cursor: pointer;

			:hover{
				opacity: 1;
				width: 30px;
				transition: 0.2s;
			}
        }

		.linkedin{
			width: 24px;
            position: absolute;
            top: -65px;
            right:115px;
			opacity: 0.6;
			transition: 0.2s;
			cursor: pointer;

			:hover{
				opacity: 1;
				width: 30px;
				transition: 0.2s;
			}
		}

		.insta{
			width: 24px;
            position: absolute;
            top: -65px;
            right:40px;
			opacity: 0.6;
			transition: 0.2s;
			cursor: pointer;

			:hover{
				opacity: 1;
				width: 30px;
				transition: 0.2s;
			}

		}
    }


	#navigator {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		list-style: none;
		padding: 0 20%;
		margin: 5% 0 0 0;

		li {
			flex: 1; /* Cada item ocupa um espaço igual */
			text-align: center;
		}

		a {
			text-decoration: none;
			color: black;
			font-weight: bold;
			color: #fff;
			font-size: 35px;
			font-family: "Lato", serif;
			font-weight: 200;
			transition: 0.1s;
			cursor: pointer;

			:hover{
				font-style: italic;
				font-weight: 400;
				transition: 0.1s
			}
		}

		img {
			max-height: 35px; /* Ajuste o tamanho da estrela conforme necessário */
			margin-top: 5px;
		}
	}

	/* Menu hambúrguer (oculto por padrão) */
	.menu-toggle {
		display: none;
		font-size: 30px;
		background: none;
		border: none;
		color: white;
		cursor: pointer;
		padding: 10px;
	}

	
    .fixed{
        transition: 0.5s;
        z-index: 1000;
        /* background: #0155af; */

    }


    #navMobile{
        width: 30px;
        height: 40px;
        display: none;
        position: absolute;
        top: 30px;
        left: 50px;
        cursor: pointer;

        span{
            float: left;
            width: 100%;         
            background: #333;
            height: 3px;
            border-radius: 20px;
            margin: 3px 0;
        }
    }  




    

        

@media only screen and  (max-width: 1680px){
  

}
  
@media only screen and  (max-width: 1440px){   

   
}

@media only screen and  (max-width: 1366px){ 
   
}

@media only screen and  (max-width: 1280px){


}

@media only screen and  (max-width: 1024px){
 

   
 

}

@media screen and (max-width: 768px) {
	.fullContent {
        padding: 0;
    }

  #navigator {
    flex-direction: column;
    display: none; /* Esconde o menu por padrão */
    width: 100%;
	background: #000;
	margin: -2px 0 0 0;
	padding: 15% 0
  }

  #navigator.show {
    display: flex; /* Exibe o menu quando ativado */
  }

  #navigator li {
    width: 100%;
    padding: 10px;
  }

  /* Exibe o menu hambúrguer */
  .menu-toggle {
    display: block;
    position: absolute;
    top: 10px;
    right: 20px;
  }
}

@media only screen and  (max-width: 480px){

   
   
}

@media only screen and  (max-width: 414px){

 
   
}

@media only screen and  (max-width: 375px){
   

}

@media only screen and  (max-width: 320px){

}
    
`