/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {HeaderCss} from './styles';
import { useEffect, useState } from 'react';
import { Loading } from "@/helpers";

import Whatss from "@/assets/imagens/whatsapp.png";
import Linkendin from "@/assets/imagens/linkedin.png";
import Insta from "@/assets/imagens/instagram.png";

import Estrela from "@/assets/imagens/estrela.svg";

export function Header(){ 
    let larguraTela = window.innerWidth;
    console.log("🚀 ~ larguraTela:", larguraTela)

    const [scroll, setScroll] = useState("");
	const [menuAberto, setMenuAberto] = useState(false);

     useEffect(() => {  
        window.addEventListener("scroll", () => {
            if(window.scrollY > 60){                
                setScroll("fixed")
            }else if(window.scrollY < 60){
                setScroll("")
            }           
        });     
    },[scroll]);

    const fechaMenu = async () => {        
        if(larguraTela < 850){
            setMenuAberto(!menuAberto)
        }      
    }; 
  
    return(            
        <HeaderCss>
            <Loading/>  
            
            <header className={scroll} id="header">  
                <div className="fullContent">          
                   
					<img src={Whatss} className='whats' alt="Whatsapp" title="Fale Conosco"/> 
					<a href="https://www.linkedin.com/company/zest1" target="_blank" rel="noreferrer"><img src={Linkendin} className='linkedin' alt="Linkeind" title="Linkedin Zest 1"/> </a>                  
					<a href="https://www.instagram.com/__zest1/" target="_blank" rel="noreferrer"><img src={Insta} className='insta' alt="Instagram Zest 1"   title="Instagram Zest 1"/></a>   
		

					{/* Botão do menu hambúrguer */}
					<button className="menu-toggle" onClick={() => setMenuAberto(!menuAberto)}>
						☰
					</button> 

                    <ul id="navigator" className={menuAberto ? "show" : ""}>
                        <li><a href="#linha" onClick={() => {fechaMenu();}}>clientes</a></li> 
						<li><img src={Estrela} alt="Estrela"/> </li>   
						<li><a href="#trabalhos"  onClick={() => fechaMenu()}>jobs</a></li> 
						<li><img src={Estrela} alt="Estrela"/> </li> 
						<li><a href="#cases"  onClick={() => fechaMenu()}>cases</a></li> 
						<li><img src={Estrela} alt="Estrela"/> </li> 
						<li><a href="#faleConosco"  onClick={() => fechaMenu()}>contato</a></li>                                   
                    </ul>
                   
                </div>
            </header> 
        </HeaderCss>  
    )
}