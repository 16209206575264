/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState} from "react";
import { CssContato } from "./css";
import Swal from 'sweetalert2';
import { visible } from "@/services/functions";

import emailjs from '@emailjs/browser';

import Whatss from "@/assets/imagens/whatsapp.png";
import Linkendin from "@/assets/imagens/linkedin.png";
import Insta from "@/assets/imagens/instagram.png";

import Zest from "@/assets/imagens/logo-zest-branco.svg";
import EmpresaMulher from "@/assets/imagens/empresa-de-mulheres.png";

const FaleConosco = () => {
	//let larguraTela = window.innerWidth;
   //alert(larguraTela)

   const [nome, SetNome] = useState("");  
   const [email, SetEmail] = useState("");
   const [mensagem, SetMensagem] = useState("");
   const [block, SetBlock] = useState("");

   useEffect(() => {

	if(nome === "" || email === "" || mensagem === ""){
	  SetBlock("")
	}else{
	  SetBlock("")
	}
	
 }, [nome, email, mensagem]);

 const EnviaContato= async (load1, load2) =>{   
	visible(load1)
	visible(load2)

	const dados = {
	  "from_name": nome,	 
	  "email" : email,
	  "mensagem" : mensagem
	}
	console.log("🚀 ~ EnviaContato ~ dados:", dados)


	const response = await emailjs.send("service_6de49fn", "template_46ux60e", dados, "lDF5NaTvP9NcnN9TD")
	console.log("🚀 ~ EnviaContato ~ response:", response)

	if(response.status === 200){
		visible(load1)
		visible(load2)
	  
		Swal.fire({
			icon: 'success',
			title: 'Enviado!',
			html: 'Email enviado com sucesso!<br/> Em breve entraremos em contato!'      
		});
	
		SetNome("")
		SetEmail("")
		SetMensagem("")
	}else{
		visible(load1)
		visible(load2)
	  Swal.fire({
		icon: 'warning',
		title: 'Atenção!',
		html: 'Não conseguimos enviar o email!'      
	  });
	}  
 
  };


  return (
 	<CssContato>			
		<div id="faleConosco">
			<div className="container">
				
				<h5>bora escrever novas histórias?</h5>	
				<h6>Fale com a gente!</h6>

				<div id="formulario">
					<form>
						<input type="text"  value={nome}  onChange={(e) => SetNome(e.target.value)} placeholder="Nome"  className="input-branco" />
						<input type="text"  value={email}  onChange={(e) => SetEmail(e.target.value)} placeholder="E-mail"  className="input-branco" />
						<textarea  rows="5" placeholder="Mensagem" value={mensagem}  onChange={(e) => SetMensagem(e.target.value)}  className="input-branco" ></textarea>
						<button type="button" onClick={() => EnviaContato("Enviar", "Aguarde")} disabled={block} id="Enviar"> Enviar</button> 
						<button type="button" disabled={"disabled"} id="Aguarde" style={{ display: 'none' }}> <i className="pi pi-spin pi-spinner" ></i> Aguarde</button> 
						
					</form>
				</div>

				
				<div id="footer">
					<div id="redes">
						<img src={Whatss} className='whats' alt="Whatsapp" title="Fale Conosco"/>
						<a href="mailto:mandajobs@zest1.com.br" className="link">michelle@zest1.com.br</a>  
						<a href="https://www.linkedin.com/company/zest1" target="_blank" rel="noreferrer"><img src={Linkendin} className='linkedin' alt="Linkeind" title="Linkedin Zest 1"/> </a>                  
						<a href="https://www.instagram.com/__zest1/" target="_blank" rel="noreferrer"><img src={Insta} className='insta' alt="Instagram Zest 1"   title="Instagram Zest 1"/></a>
					</div>					
				</div>	

					<div id="logotipos">
						<img src={Zest} alt="Zest One" title="Zest 1"/>
						<img src={EmpresaMulher} alt="Woman Owned" title="Woman Owned"/>
					</div>	
			
			</div>
		</div>

	</CssContato> 
  );  
}

export default FaleConosco;