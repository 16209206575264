import styled from "styled-components";
//import FundoRosa from "../../assets/imagens/fundoHome.jpg";
import FundoCase from "../../assets/imagens/fundoCases.jpg";
//import FundoContato from "../../assets/imagens/fundoContato.jpg";
import esquerda from "../../assets/imagens/seta-esquerda.png";
import direita from "../../assets/imagens/seta-direita.png";

import fundo1 from "../../assets/imagens/fundo1.jpg";
import fundo2 from "../../assets/imagens/fundo2.jpg";
import fundo3 from "../../assets/imagens/fundo3.jpg";
import fundo4 from "../../assets/imagens/fundo4.jpg";

export const CssHome = styled.div`

	width: 100%;
	height: 100vh;
	position: relative;
	padding: 0;
	margin: 0;	
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;

	animation: mudarFundo 10s infinite linear;
	-webkit-animation:  mudarFundo 10s infinite linear;

	@keyframes mudarFundo {
		0% { background: url(${fundo1}); }
		20% { background: url(${fundo2}); } /* Mantém a imagem fixa */
		25% { background: url(${fundo3}); }
		45% { background: url(${fundo4}); }
		50% { background: url(${fundo4}); }
		70% { background: url(${fundo3}); }
		75% { background: url(${fundo2}); }
		95% { background: url(${fundo2}); }
		100% { background: url(${fundo1}); } 
	}

	#container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100vh;
	}

	#Logo {
		margin-bottom: 20px;

		/* display: flex;
		justify-content: center; 
		align-items: center; 
		height: 100vh; */

		img {
			max-width: 100%; /* Garante que a imagem não ultrapasse os limites */
			max-height: 50vh; /* Ajusta para caber na tela sem ultrapassar */
			margin-bottom: 10%;
		}
	}

	#texto {
		font-size: 60px;
		font-weight: bold;
		text-align: center;
		position: relative;
		display: inline-block;
		font-weight: 100;
		color: #fff;
		transition: transform 0.5s ease-in-out;	

		strong {
			display: inline-block;
			transition: opacity 0.5s ease-in-out;
			font-style: italic;
			font-weight: 500
		}
	}

	.fade-in {
		opacity: 1;
	}

	.fade-out {
		opacity: 0;
	}

	.move-in {
		transform: translateX(0);
	}

	.move-out {
		transform: translateX(20px); /* Mover um pouco para a direita ao desaparecer */
	}

	
	#saibaMais{
		border: 1px #fff solid;
		text-decoration: none;
		color: #fff;
		font-size: 20px;
		font-weight: 300;
		padding: 15px 45px 15px 30px;
		position: absolute;
		bottom:5%; /* Fixa o botão no rodapé do container */
		left: 50%;
		transform: translateX(-50%); /* Centraliza o botão */
		cursor: pointer;
		transition: 0.5s;

		:hover{
			background: #fff;
			color: #333;
			transition: 0.5s;

			i{
				color: #333
			}
		}
		
		i{
			position: absolute;
			top:40%;
			right: 8%;
			font-size: 14px;
		}
	}

	@media only screen and  (max-width: 1550px){ 
		
	
	
	} 

  	@media only screen and  (max-width: 1370px){
   
	}   


	@media only screen and  (max-width: 1280px){

	}


	@media only screen and  (max-width:1024px){

	}
	

	@media only screen and  (max-width: 915px){
		#texto {
			font-size: 50px;
		}

		#Logo img {
			max-width: 90%;
			max-height: 40vh;
		}

		#container{
			height: 90vh;
		}

	}

	@media only screen and  (max-width: 480px){
		#saibaMais{
			bottom: 10%
		}

		animation: none;
		-webkit-animation: none;
		background: url(${fundo1}) !important;


	}

	@media only screen and  (max-width: 420px){

	}

	@media only screen and  (max-width: 395px){

	}

  @media only screen and  (max-width: 330px){

  }


`;

export const CssQuem = styled.div`

	float: left;
	width: 100%;
	min-height: 200px;
	/* height: 100vh; */
	position: relative;
	padding: 0;
	margin: 0;
	background: #1a1a1a;

	#quemSomos{
		width: 100%;
		min-height: 330px;
		position: relative;
		float: left;
		padding: 5% 0%;

		h1{
			font-size: 45px;
			font-weight: 200;
			text-align: center;
			color: #fff;
			margin-bottom: 3%;

			span{
				font-style: italic;
				font-weight: 500
			}
		}

		h2{
			font-size: 35px;
			font-weight: 200;
			text-align: center;
			color: #fff;
			line-height: 50px;

			strong{
				text-decoration: underline;
				text-decoration-color: #fff; /* Cor do sublinhado */
				text-decoration-thickness: 1px; /* Espessura do sublinhado */			
				font-weight: 200
			}

			span{
				display: block;
			}
		}		
	}

	#linha{
		width: 100%;
		position: relative;
		float: left;
		text-align: center;
		margin: 0 0 2% 0;
	}

	#arraste{
		width: 100%;
		position: relative;
		float: left;
		text-align: center;
		margin: 1% 0;
		color: #fff;

		p{
			font-weight: 100;
			font-size: 24px;
			padding: 10px 0;
			font-style: italic
		}

		h3{
			font-size: 40px;
			font-weight: 300;
			padding: 15px 0 25px 0;

			i{
				font-weight: 500
			}
		}

		img {
			transition: transform 0.3s ease; /* Transição suave */
				:hover {
					transform: rotate(25deg); /* Rotação de 5 graus */
				}
		}

	}

	#clientes{
		width: 100%;
		position: relative;
		float: left;
		text-align: center;
		padding: 0 2%;

		img{
			max-width: 130px;
		}
	}
	

	.slick-slide img{
		display: inline!important
	}

	.slick-prev{
		background: url(${esquerda});
		width: 48px;
		height: 48px;
		left: -50px;
	}

	.slick-next{
		background: url(${direita});
		width: 48px;
		height: 48px;
		right: -50px
	}

	.slick-prev:before, .slick-next:before{
		display: none
	}

	#video{
		width: 100%;
		position: relative;
		float: left;
		text-align: center;
		/* padding: 0 17%; */
		margin: 5% 0;

		img{
			cursor: pointer
		}
		
	}

	
	#lg {
		width: 100%;
		max-width: 810px;
		margin: 1%;
	}

	@media only screen and  (max-width: 1550px){ 
		
	
	
	} 

  	@media only screen and  (max-width: 1370px){
   
	}   


	@media only screen and  (max-width: 1280px){

	}


	@media only screen and  (max-width:1024px){

	}
	

	@media only screen and  (max-width: 915px){
		

	}

	@media only screen and  (max-width: 480px){

		#quemSomos {
			padding: 20% 2% 2% 2%;
			

			h1{
				font-size: 35px;
				margin-bottom:10%;
			}

			h2{
				font-size:25px;	
				line-height: 40px;
				margin-bottom:10%;

				span{
					display: none
				}
			}
			
		}

		#arraste h3{
			font-size: 30px;
		}

		 #clientes {
			padding: 0px 20%;

			img {
				max-width: 150px;
			}
		 }
		

		#video{
			padding: 0 5%
		}
	}

	@media only screen and  (max-width: 420px){

	}

	@media only screen and  (max-width: 395px){

	}

	@media only screen and  (max-width: 330px){

	}


`;

export const CssJobs = styled.div`

	#trabalhos{
		float: left;
		width: 100%;
		min-height: 200px;
		position: relative;
		padding: 8% 0;
		background: #242424;

	}
		

	#lateral{
		float: left;
		width: 30%;
		color: #fff;
		font-size: 24px;
		font-weight: 300;
		margin-top: 6%;

		a{
			float: left;
			position: relative;
			border: 1px #fff solid;
			text-decoration: none;
			color: #fff;
			font-size: 20px;
			font-weight: 300;
			padding: 15px 30px 15px 30px;
		}

		p{
			padding: 10px 0;
			margin-bottom: 30px;

			span{
				text-decoration: underline;
				text-decoration-color: #fff; /* Cor do sublinhado */
				text-decoration-thickness: 1px; /* Espessura do sublinhado */			
				font-weight: 200
			}
		}

		h4{
			font-size: 40px;
			font-style: italic;
			font-weight: 500;
			padding-bottom: 20px;
		}
	}

	#jobs{
		float: left;
		width: 70%;
		color: #fff;
	}

	.job{
		float: left;
		width: 50%;
		padding: 10px 5px;
		margin: 15px 0;

		strong{
			font-weight: 500;
			font-size: 24px;
			display: block;
			text-shadow: 1px 1px 0px #000, 2px 2px 2px rgba(0, 0, 0, 0.5);
		}

		small{
			font-weight: 300;
			font-size: 14px;
			text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
			opacity: 0.8
		}

		img{
			float: left;
			margin-right: 20px;
		}
	}
	

	@media only screen and  (max-width: 1550px){ 
		
	
	
	} 

  	@media only screen and  (max-width: 1370px){
   
	}   


	@media only screen and  (max-width: 1280px){

	}


	@media only screen and  (max-width:1024px){

	}
	

	@media only screen and  (max-width: 915px){
		

	}

	@media only screen and  (max-width: 480px){

		#trabalhos{padding: 10% 5%;}

		#lateral, #jobs{
			width: 100%;
		}

		.job{
			width: 100%;

			strong{
				font-size: 20px;
			}

			img{
				width: 105px;
				height: 105px;
				margin-right: 10px;
			}
		}
		
	}

	@media only screen and  (max-width: 420px){

	}

	@media only screen and  (max-width: 395px){

	}

	@media only screen and  (max-width: 330px){

	}


`;

export const CssCases = styled.div`
	#background {
		width: 100%;
		height: 100vh;
		position: relative;
		padding: 0;
		margin: 0;
		background: url(${FundoCase});
		background-position: top center;
		background-repeat: no-repeat;
		background-size: cover;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	#cases {
		width: 90%;
		max-width: 1400px;
		height: auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
		flex-wrap: wrap; /* Permite que os elementos fiquem um abaixo do outro em telas pequenas */
	}

	#borrao {
		width: 48%;
		max-width: 600px;
		background: rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(20px);
		-webkit-backdrop-filter: blur(10px);
		border-radius: 20px;
		border: 1px solid #000;
		padding: 5%;
		color: #fff;
		font-size: 18px;
		text-align: left;
		
		h5 {
			font-size: 40px;
			font-style: italic;
			font-weight: 600;
			margin-bottom: 20px;
		}

		h6 {
			font-size: 25px;
			font-style: italic;
			font-weight: 400;
			margin-bottom: 20px;
		}

		p {
			padding: 1rem 0;
			font-weight: 100;
			line-height: 30px;

			span{
				text-decoration: underline;
				text-decoration-color: #fff; /* Cor do sublinhado */
				text-decoration-thickness: 1px; 
			}
		}

		a {
			border: 1px solid #fff;
			text-decoration: none;
			color: #fff;
			font-size: 20px;
			font-weight: 300;
			padding: 15px 30px;
			cursor: pointer;
			transition: 0.5s;
			display: inline-block;
			margin-top: 15px;

			:hover {
				background: #fff;
				color: #333;
				transition: 0.5s;

				i {
					color: #333;
				}
			}
		}
	}

	#portifolio {
		width: 48%;
		max-width: 500px;
		padding: 10px;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		a {
			font-weight: 300;
			font-size: 20px;
			color: #fff;
			text-decoration: underline;
			text-decoration-color: #fff;
			text-decoration-thickness: 1px;

			:hover {
				font-style: italic;
				font-weight: 600;
			}
		}

		section {
			width: 100%;
			text-align: center;
			padding: 15px;
			cursor: pointer;
			display: flex;
			justify-content: center;

			img {
				width: 100%;
				max-width: 400px;
				height: auto;
				transition: transform 0.3s ease-in-out;
			}

			img:hover {
				transform: scale(1.05);
			}
		}
	}

	/* Ajustes para telas menores */
	@media only screen and (max-width: 1024px) {
		#cases {
			flex-direction: column;
			align-items: center;
		}

		#borrao, #portifolio {
			width: 90%;
		}
	}

	@media only screen and (max-width: 768px) {
		#portifolio {
			width: 100%;
		}

		#borrao {
			width: 95%;
		}

		#portifolio section img {
			max-width: 250px;
		}
	}

	@media only screen and (max-width: 480px) {
		#borrao {
			padding: 5%;
		}

		#portifolio section img {
			max-width: 340px;
		}

		#cases{
			width: 100%;
			float: left;
			margin: 5% 0 0 0;
		}

		#background {
			height: 160vh
		}
	}

	@media only screen and (max-width: 375px) {
		#background {
			height: 185vh
		}

	}
`;

export const CssContato = styled.div`
	
	width: 100%;
	height: 100vh;
	position: relative;
	padding: 0;
	margin: 0;
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;

	/* Definição da animação */
	animation: mudarFundo 10s infinite linear;

	@keyframes mudarFundo {
		0% { background-image: url(${fundo1}); }
		20% { background-image: url(${fundo2}); } /* Mantém a imagem fixa */
		25% { background-image: url(${fundo3}); }
		45% { background-image: url(${fundo4}); }
		50% { background-image: url(${fundo4}); }
		70% { background-image: url(${fundo3}); }
		75% { background-image: url(${fundo2}); }
		95% { background-image: url(${fundo2}); }
		100% { background-image: url(${fundo1}); } 
	}
	
	#LoadingPage{
		display: none
	}

	#faleConosco {
		width: 100%;
		float: left;
		min-height: 200px;
		padding:3% 2%;
		color: #fff;
		
		

		h5{
			text-align: center;
			font-size: 24px;
			font-weight: 300;
			padding: 10px 0
		}

		h6{
			text-align: center;
			font-size: 40px;
			font-weight: 300;
			font-style: italic;
			padding: 10px 0;
			margin-bottom: 5%;
		}

		::-webkit-input-placeholder { /* Edge */
			color: #fff;		
			font-family: "Lato", serif;
		}

		.input-branco::placeholder {
			color: white;
			opacity: 1; /* Garante que a cor branca seja visível */
		}

		#formulario{
			width: 100%;
			max-width:1024px;
			height: auto;
			display: inline;

			input[type="text"],
			textarea
			{	
				float: left;
				width: 100%;
				background:none;
				border:1px #fff solid;
				padding:10px;
				color: #fff;
				font-size: 20px;
				font-family: "Lato", serif;
				font-weight: 300;
				transition: 0.5s;
				height:80px;
				margin: 10px 0;
				/*
					:focus {
						transition: 0.5s;
						border: 2px solid #fff;
					}
				*/		

			}

			/* Inputs */
			
			
			button{
				float: left;
				width: 100%;
				background:none;
				border:1px #fff solid;
				padding:10px;
				color: #fff;
				font-size: 20px;
				font-family: "Lato", serif;
				font-weight: 300;
				transition: 0.5s;
				height:60px;

				:hover{
					transition: 0.5s;
					background: #fff;
					color: #000;
					border: 2px #fff solid;
					cursor: pointer;
				}

				:disabled {
					opacity: 0.7;
					cursor: not-allowed!important;		
				}
			}
		}
		

	}

	#footer{
		width: 100%;
		float: left;
		padding: 1%;
		color: #fff;
		text-align: center;
		display: flex;
  		justify-content: center; 
		margin-top: 5%;

		  #redes {
			display: flex;
			align-items: center; /* Alinha os itens verticalmente */
			gap: 20px; /* Espaçamento entre os itens */
			max-width: 50%; /* Limita a largura dos itens para não ocupar todo o footer */
			flex-wrap: wrap; /* Permite quebra de linha em telas menores */
			font-size: 24px;
			font-weight: 300;
			margin-bottom: 30px;

			img{
				opacity: 0.6;
				transition: 0.2s;
				cursor: pointer;
				transition: 0.2s;
				width: 24px;

				:hover{
					opacity: 1;
					width: 30px;
					transition: 0.2s;
				}
			}

			.link{
				text-decoration: underline;
				text-decoration-color: #fff; /* Cor do sublinhado */
				text-decoration-thickness: 1px; 
				color: #fff;
			}
		}		
	}

	#logotipos{
			width: 100%; /* Mantém a largura total */
			display: flex;
			justify-content: center; /* Centraliza os itens horizontalmente */
			align-items: center; /* Alinha verticalmente */
			gap: 30px; /* Espaço entre os logotipos */
			flex-wrap: nowrap;	

			img{
				max-width: 120px;
				height: auto;
			}
		}

	

	/* Ajustes para telas menores */
	@media only screen and (max-width: 1024px) {
		
	}

	@media only screen and (max-width: 768px) {
		#redes {
			display: flow;		
		 	justify-content: center; 
			gap: 10px;
			max-width: 95%!important;
		}

		 #faleConosco #formulario input[type="text"],  #faleConosco #formulario textarea{
			height: 60px
		}
	}

	@media only screen and (max-width: 480px) {
		animation: none;
		-webkit-animation: none;
		background: url(${fundo1}) !important;
	}

	@media only screen and (max-width: 390px) {
		
	}
`;
